import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutComponent} from './layout/layout.component';
import {SpeechRecognitionModule} from './speech-recognition/speech-recognition.module';
import {ChatModule} from './chat/chat.module';
import {FormsModule} from '@angular/forms';
import {VoiceSynthesisModule} from './voice-synthesis/voice-synthesis.module';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    SpeechRecognitionModule,
    ChatModule,
    HttpClientModule,
    VoiceSynthesisModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
