import {Component, OnInit} from '@angular/core';
import {SpeechRecognitionService} from '../speech-recognition/speech-recognition.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private speech: SpeechRecognitionService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  changeMicState() {
    this.speech.changeState();
  }
}
