import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VoiceSynthesisService {
  private readonly available: boolean;
  private voices: SpeechSynthesisVoice[];

  constructor() {
    this.available = !!window.speechSynthesis;
    console.log(window.speechSynthesis);

    if (this.available) {
      this.getVoiceList();
      if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = this.getVoiceList;
      }
    }
  }

  speak(text: string) {
    const utter = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utter);
    console.log('speak ', text);
  }

  private getVoiceList() {
    this.voices = window.speechSynthesis.getVoices();
    console.log('voices', this.voices);
  }
}
