import {Injectable} from '@angular/core';
import {Message} from './chat.model';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  messages: Observable<Message[]>;
  // tslint:disable-next-line:variable-name
  private _messages: BehaviorSubject<Message[]>;
  private dataStore: {
    messages: Message[]
  };

  constructor() {
    this.dataStore = {messages: []};
    this._messages = new BehaviorSubject([]);
    this.messages = this._messages.asObservable();
  }

  addMessage(message: Message) {
    console.log('addMessage', message);
    this.dataStore.messages = [...this.dataStore.messages, message];
    this._messages.next(this.dataStore.messages);
  }
}
