import {Injectable} from '@angular/core';
import {Annyang, CommandOption, StartOptions} from 'annyang';
import {Subject} from 'rxjs';
import {SpeechStatus} from './speech-recognition.model';

declare var annyang: Annyang;

@Injectable({
  providedIn: 'root'
})
export class SpeechRecognitionService {
  private readonly available: boolean;
  private status: SpeechStatus;

  onResult: Subject<string[]>;
  onStatusChange: Subject<SpeechStatus>;
  onResultMatch: Subject<string[]>;
  onResultNoMatch: Subject<string[]>;
  active = false;

  constructor() {
    this.available = !!annyang;
    this.status = SpeechStatus.INACTIVE;
    this.onResult = new Subject<string[]>();
    this.onStatusChange = new Subject<SpeechStatus>();
    this.onResultMatch = new Subject<string[]>();
    this.onResultNoMatch = new Subject<string[]>();

    if (this.available) {
      annyang.addCallback('result', (result) => this.onResult.next(result as unknown as string[]));
      annyang.addCallback('resultNoMatch', (result) => this.onResultNoMatch.next(result as unknown as string[]));
    }
  }

  getAnnyang(): Annyang {
    return annyang;
  }

  isAvailable(): boolean {
    return this.available;
  }

  addCommands(commands: CommandOption) {
    annyang.addCommands(commands);
  }

  start(options: StartOptions) {
    this.active = true;
    annyang.setLanguage('pl');
    annyang.start(options);
    this.changeStatus(SpeechStatus.ACTIVE);
  }

  resume(silent?: boolean) {
    annyang.resume();
    this.active = true;
    this.changeStatus(SpeechStatus.ACTIVE, silent);
  }

  pause(silent?: boolean) {
    annyang.pause();
    this.active = false;
    this.changeStatus(SpeechStatus.PAUSED, silent);
  }

  changeState(silent?: boolean): boolean {
    if (!this.active) {
      this.resume();
    } else {
      this.pause();
    }
    console.log('changeState', this.active);
    return this.active;
  }

  private changeStatus(status: SpeechStatus, silent?: boolean) {
    this.status = status;
    if (!silent) {
      this.onStatusChange.next(status);
    }
  }
}
