import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChatComponent} from './chat.component';
import {MessageComponent} from './message/message.component';
import {ChatService} from './shared/chat.service';
import {FlexModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [ChatComponent, MessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexModule,
    MaterialModule
  ],
  providers: [
    ChatService
  ],
  exports: [
    ChatComponent
  ]
})
export class ChatModule {
}
