import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Message, MessageSourceEnum} from './shared/chat.model';
import {ChatService} from './shared/chat.service';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {CommandOption} from 'annyang';
import {SpeechRecognitionService} from '../speech-recognition/speech-recognition.service';
import {SpeechStatus} from '../speech-recognition/speech-recognition.model';
import {MatSnackBar} from '@angular/material';
import {VoiceSynthesisService} from '../voice-synthesis/voice-synthesis.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatComponent implements OnInit {
  messages$: Observable<Message[]>;
  messages: Message[];
  text = '';

  constructor(
    private chatService: ChatService,
    private speech: SpeechRecognitionService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private voice: VoiceSynthesisService,
    private http: HttpClient) {
  }

  ngOnInit() {
    const commands: CommandOption = {
      WYŚLIJ: () => this.sendMessage(),
      WYCZYŚĆ: () => this.clearMessage(),
      'wyłącz mikrofon': () => this.turnOffMic(),
      ODŚWIEŻ: () => window.location.reload(),
      'jaka jest pogoda': () => this.getWeatherData()
    };

    this.speech.addCommands(commands);
    this.speech.onResult.subscribe(data => {
      console.log('on result', data);
    });

    this.speech.onResultNoMatch.subscribe(data => {
      this.writeMessage(data[0].trim());
    });

    this.speech.onStatusChange.subscribe(status => {
      console.log('status', status);
      switch (status) {
        case SpeechStatus.ACTIVE:
          this.snackBar.open('Wykrywanie głosu włączone', 'OK', {
            duration: 3000
          });
          break;
        case SpeechStatus.PAUSED:
          this.snackBar.open('Wykrywanie głosu zatrzymane', 'OK', {
            duration: 3000
          });
          break;
      }
    });
    this.speech.start({autoRestart: true});

    this.messages$ = this.chatService.messages;

    this.messages$.subscribe(data => {
      this.messages = _.cloneDeep(data);
      this.cd.detectChanges(); // TODO fix problem with change detection
      if (data && data.length > 0) {
        const lastMessage: Message = data[data.length - 1];
        if (lastMessage.source === MessageSourceEnum.EXTERNAL) {
          this.speech.pause(true);
          this.voice.speak(lastMessage.text);
          this.speech.resume(true);
        } else if (lastMessage.text !== 'Jaka jest pogoda?') {
          this.sendMockResponse();
        }
      }
    });
  }

  turnOffMic(): void {
    this.speech.pause();
    console.log('paused');
  }

  writeMessage(text: string) {
    this.text = text;
    this.cd.detectChanges();
  }

  clearMessage() {
    this.text = '';
    this.cd.detectChanges();
  }

  sendMessage() {
    if (this.text.length > 0) {
      const msg: Message = {
        source: MessageSourceEnum.OWN,
        text: this.text
      };
      this.chatService.addMessage(msg);
      this.clearMessage();
    }
  }

  sendMockResponse() {
    const delay = Math.floor(Math.random() * (2000 - 300 + 1)) + 300;

    const answers = ['Na świecie jest 10 rodzajów ludzi: ci, którzy rozumieją system binarny i ci, którzy go nie rozumieją',
      'Prawdziwy informatyk ma grupę krwi C',
      'Wiecie, dlaczego pralki mają tyle różnych programów? Żeby kobiety też mogły programować',
      'Jak śni programista? Na Javie!',
      'Dlaczego nie można programować w Boże Narodzenie? Bo bug się rodzi',
      'Najkrótszy żart na świecie? Programista HTML',
      'Dlaczego programista na umycie głowy zużywa całą butelkę szamponu? Bo w instrukcji jest napisane: nałożyć, spłukać, czynność powtórzyć',
      'Jaki język jest najczęściej używany przez programistów? Wulgarny.',
      'Co robi informatyk budząc się na kacu? Testuje pamięć.',
      'Co mówi informatyk, kiedy dostanie na urodziny pendrive? Dzięki za pamięć.',
      'Co mówi ksiądz na ślubie informatyka? Pobieranie zakończone',
      'Jakimi kartami grają informatycy? Kartami graficznymi',
      'Dobry informatyk wiesza się wraz ze swoim komputerem!',
      'Jak najczęściej informatycy zwracają się do swoich żon? Myszko.',
      'Jaka jest ulubiona piosenka adminów? Chcę oglądać twoje logi-logi, logi, logi.'
    ];
    const randomAnswerIndex = Math.floor(Math.random() * (answers.length - 1 + 1));

    const msg: Message = {
      source: MessageSourceEnum.EXTERNAL,
      text: answers[randomAnswerIndex]
    };
    setTimeout(() => {
      this.chatService.addMessage(msg);
    }, delay);
  }

  getWeatherData() {
    this.chatService.addMessage({text: 'Jaka jest pogoda?', source: MessageSourceEnum.OWN});
    const url = 'http://api.openweathermap.org/data/2.5/weather?q=Kielce,pl&units=metric&lang=pl&appid=46a7157202b5b6094802b09d303f8080';
    this.http.get<any>(url).subscribe(data => {
      console.log('data', data);
      this.chatService.addMessage( {
        source: MessageSourceEnum.EXTERNAL,
        text: 'Obecnie jest ' + data.main.temp + ' stopni oraz ' + data.weather[0].description
      });
    });
  }
}
